.app {
    font-family: -apple-system, BlinkMacSystemFont,'Alegreya', sans-serif;
}

.navbar {
    opacity: 0.9;
    zIndex: 1;
    color: rgba(0, 0, 0, 1);
}

.container {
   background-color: rgba(255, 255, 255, 0.9);
   color: rgba(0, 0, 0, 1);
   position: relative;
   top: 100px;
   left: 0px;
   zIndex: 0;
   
       -webkit-animation: fadein 2.5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2.5s; /* Firefox < 16 */
        -ms-animation: fadein 2.5s; /* Internet Explorer */
         -o-animation: fadein 2.5s; /* Opera < 12.1 */
            animation: fadein 2.5s;
}

.logo {
width: 40px;
padding: 5px 5px 5px 5px;
margin-bottom: 10px;
}

.alink, .alink:hover, .alink:focus, .alink:active {
    padding: 8px;
    color: rgb(0, 0, 0);
    background-color: rgba(255, 255, 255, 0);
    text-decoration: none;
    border: none;
    border-color: none;
    border-radius: 0px;
}

.navbarlink {
    color: rga(0, 0, 0);
    margin-left: 10px;
    left: 10%;
}

.hvr-underline-from-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: rgb(169,169,169);
  height: 4px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-left:hover:before, .hvr-underline-from-left:focus:before, .hvr-underline-from-left:active:before {
  right: 0;
}

.researchpic {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}

bg {
    position: "absolute";
    zIndex: -1;
    top: 0;
    left: 0;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}